<template>
    <div class="DashboardLayout">
        <Sidebar/>
        <div class="main" >
            <loader-comp v-if="this.loaderState"/>
            <router-view />
        </div>
    </div>
</template>
<script>
import Sidebar from '../components/Sidebar.vue';
import LoaderComp from '../components/LoaderComp.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        Sidebar,
        LoaderComp
    },
    computed: {
        ...mapGetters(['loaderState'])
    },
}
</script>
<style lang="scss">
    .DashboardLayout {
        display: flex;
        .main {
            position: relative;
            width: 100%;
            padding-left: 15%;
        }
    }
</style>