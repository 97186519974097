import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    // something vue-i18n options here ...
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            language: {
                ShoppingCart: 'Shopping Cart',
                LoginRegister: 'Login / Register',
                Profile: 'Profile',
                Home: 'Home',
                Products: 'Products',
                Categories: 'Categories',
                Tags: 'Tags',
                FAQANDSUPPORT: 'Contact us',
                BestSellers: 'Best Sellers',
                NewArrivals: 'New Arrivals',
                DiscountDeals: 'Discount Deals',
                More: 'More',
                Logout: 'Logout',
                CartIsEmpty: 'Your cart is empty.',
                ContinueShopping: 'Continue Shopping',
                GrapAtLeastOneItem: 'Grap at least one item to get your order summary',
                OrderSummary: 'Order Summary',
                OriginalPrice: 'Original Price',
                Savings: 'Savings',
                Promo: 'Promo',
                ShippingFee: 'Shipping Fee',
                Tax: 'Tax',
                Total: 'Total',
                HavePromoCode: 'Have a promo code?',
                Apply: 'Apply',
                SelectPaymentMethod: 'Select a payment method',
                PayOnDelivery: 'Pay on delivery',
                Or: 'Or',
                CreditCard: 'Credit Card',
                ChooseStyle: 'Choose style',
                ChooseSize: 'Choose size',
                Remove: 'Remove',
                Category: 'Category',
                Tag: 'Tag',
                Styles: 'Styles',
                Rating: 'Rating',
                Stock: 'Stock',
                Price: 'Price',
                Size: 'Size',
                Quantity: 'Quantity',
                AddToCart: 'Add to Cart',
                ProductTags: 'Product Tags',
                ProductDescription: 'Product Description',
                Login: 'Login',
                Register: 'Register',
                YourEmail: 'Your email',
                YourPassword: 'Your password',
                DontHaveAnAccount: 'Don\'t have an account?',
                Name: 'Name',
                ConfirmPassword: 'Confirm password',
                YourAddress: 'Your address',
                YourPhone: 'Your phone',
                YourPassword: 'Your password',
                AlreadyHaveAnAccount: 'Already have an account?',
                MyInformations: 'My Informations',
                Favs: 'Favs',
                MyFavs: 'My Favs',
                MyOrders: 'My Orders',
                ComingSoon: 'Coming Soon!',
                User: 'User',
                Price: 'Price',
                Products: 'Products',
                Status: 'Status',
                NoOrdersFound: 'No orders found',
                NoFavsFound: 'No favorites found',
                ShoppingNow: 'Shopping now!',
                Pending: 'Pending',
                Processing: 'Processing',
                Shipped: 'Shipped',
                Completed: 'Completed',
                Canceled: 'Canceled',
                Update: 'Update',
                Reset: 'Reset',
                Address: 'Address',
                Phone: 'Phone',
                Email: 'Email',
                Password: 'Password',
                ConfirmPassword: 'Confirm password',
                Update: 'Update',
                Reset: 'Reset',
                Location: 'Location',
                Email: 'Email',
                CallUs: 'Call Us',
                ContactUs: 'Contact Us',
                FillOutTheFormBelowAndWeWillGetBackToYouAsSoonAsPossible: 'Fill out the form below and we will get back to you as soon as possible.',
                SendMessage: 'Send Message',
                Subject: 'Subject',
                Message: 'Message',
                OurProducts: 'Our Products',
                OurCategories: 'Our Categories',
                FAQAndSupport: 'Contact us',
                TermsAndConditions: 'Terms & Conditions',
                PrivacyPolicy: 'Privacy Policy',
                AllRightsReserved: 'All rights reserved',
                Filters: 'Filters',
                NoProductsFound: 'No products found',
                SubCategory: 'Sub Category',
                Subcategories: 'Sub Categories',
                Search: 'Search',
                All: 'All',
                AppliedSuccessfully: 'Applied Successfully !',
                Image: 'Image',
                Actions: 'Actions',
                ForgotPassword: 'Forgot Password',
                EnterEmailLink:'Enter your email address and we will send you a code to reset your password.',
                Send: 'Send',
                EnterCodeRecived:'Enter your new password and code recived.',
                ChangePassword: 'Change Password',
                didForgotPassword: 'did you forgot you password ?',
                forgotPasswordLink: 'forgot password',
            }
        },
        ar: {
            language: {
                ShoppingCart: 'سلة التسوق',
                LoginRegister: 'تسجيل الدخول / تسجيل',
                Profile: 'حساب',
                Home: 'الرائيسية',
                Products: 'المنتجات',
                Categories: 'الفئات',
                Tags: 'التاكس',
                BestSellers: 'الأكثر مبيعا',
                NewArrivals: 'الوافدين الجدد',
                DiscountDeals: 'عروض الخصم',
                More: 'المزيد',
                Logout: 'تسجيل الخروج',
                CartIsEmpty: 'عربة التسوق فارغة',
                ContinueShopping: 'مواصلة التسوق',
                GrapAtLeastOneItem: 'احصل على عنصر واحد على الأقل للحصول على ملخص طلبك',
                OrderSummary: 'ملخص الطلب',
                OriginalPrice: 'سعر الأصلي',
                Savings: 'خصم',
                Promo: 'خصم',
                ShippingFee: 'شحنة',
                Tax: 'ضريبة',
                Total: 'المجموع',
                HavePromoCode: 'هل لديك رمز خصم؟',
                Apply: 'تطبيق',
                SelectPaymentMethod: 'إختر طريقة الدفع',
                PayOnDelivery: 'دفع عند التوصيل',
                Or: 'أو',
                CreditCard: 'بطاقة الائتمان',
                ChooseStyle: 'اختر اللون',
                ChooseSize: 'اختر الحجم',
                Remove: 'إزالة',
                Category: 'فئة',
                Tag: 'تاكس',
                Styles: 'الألوان',
                Rating: 'تقييم',
                Stock: 'المخزن',
                Price: 'السعر',
                Size: 'الحجم',
                Quantity: 'الكمية',
                AddToCart: 'إضافة إلى السلة',
                ProductTags: 'معلومات المنتج',
                ProductDescription: 'وصف المنتج',
                Login: 'تسجيل الدخول',
                Register: 'تسجيل',
                YourEmail: 'البريد الإلكتروني',
                YourPassword: 'كلمة المرور',
                DontHaveAnAccount: 'ليس لديك حساب؟',
                Name: 'الاسم',
                ConfirmPassword: 'تأكيد كلمة المرور',
                YourAddress: 'العنوان',
                YourPhone: 'رقم الهواتف',
                YourPassword: 'كلمة المرور',
                AlreadyHaveAnAccount: 'لديك حساب؟',
                MyInformations: 'معلوماتي',
                Favs: 'المفضلة',
                MyFavs: 'مفضلاتي',
                MyOrders: 'طلباتي',
                ComingSoon: 'بعض الأحيان',
                User: 'مستخدم',
                Price: 'السعر',
                Products: 'المنتجات',
                Status: 'الحالة',
                NoOrdersFound: 'لا يوجد طلبات',
                NoFavsFound: 'لا يوجد مفضلات',
                ShoppingNow: 'شراء الان',
                Pending: 'في الانتظار',
                Processing: 'معالجة',
                Shipped: 'تم التوصيل',
                Completed: 'منجوم',
                Canceled: 'ملغي',
                Update: 'تحديث',
                Reset: 'إعادة تعيين',
                Address: 'العنوان',
                Phone: 'رقم الهواتف',
                Email: 'البريد الإلكتروني',
                Password: 'كلمة المرور',
                ConfirmPassword: 'تأكيد كلمة المرور',
                Update: 'تحديث',
                Reset: 'إعادة تعيين',
                Location: 'المن المنطقي',
                Email: 'البريد الإلكتروني',
                CallUs: 'أسئلة عنا',
                ContactUs: 'تواصل معنا',
                FillOutTheFormBelowAndWeWillGetBackToYouAsSoonAsPossible: 'اكتب باقي النموذج أدناه ونصب عليك بعد بعض الوقت.',
                SendMessage: 'إرسال رسالة',
                Subject: 'موضوع',
                Message: 'رسالة',
                OurProducts: 'منتجاتنا',
                OurCategories: 'فئاتنا',
                FAQANDSUPPORT: 'تواصل معنا',
                TermsAndConditions: 'شروط وأحيان',
                PrivacyPolicy: 'سياسة الخصوصية',
                AllRightsReserved: 'جميع الحقوق محفوظة',
                Filters: 'الفلاتر',
                NoProductsFound: 'لا يوجد منتجات',
                FAQAndSupport: 'تواصل معنا',
                SubCategory: 'فئة فرعية',
                Subcategories: 'الفئات الفرعية',
                Search: 'بحث',
                All: 'الكل',
                AppliedSuccessfully: 'تم التطبيق بنجاح',
                Image: 'صورة',
                Actions: 'الإجراءات',
                ForgotPassword: 'نسيت كلمة المرور',
                EnterEmailLink:'أدخل عنوان بريدك الإلكتروني وسنرسل لك رمزًا لإعادة تعيين كلمة المرور الخاصة بك',
                Send: 'إرسال',
                EnterCodeRecived:'أدخل كلمة المرور الجديدة والرمز المستلم',
                ChangePassword: 'تغيير كلمة المرور',
                didForgotPassword: 'هل نسيت كلمة المرور؟',
                forgotPasswordLink: 'نسيت كلمة المرور',
            }
        },
        fr: {
            language: {
                ShoppingCart: 'Panier d\'achat',
                LoginRegister: 'Connexion / Inscription',
                Profile: 'Profil',
                Home: 'Accueil',
                Products: 'Produits',
                Categories: 'Catégories',
                Tags: 'Tags',
                FAQANDSUPPORT: 'Nous contacter',
                BestSellers: 'Meilleurs ventes',
                NewArrivals: 'Nouveautés',
                DiscountDeals: 'Promotions',
                More: 'Plus',
                Logout: 'Déconnexion',
                CartIsEmpty: 'Votre panier est vide.',
                ContinueShopping: 'Continuer l\'achat',
                GrapAtLeastOneItem: 'Choisissez au moins un article pour obtenir le récapitulatif de votre commande',
                OrderSummary: 'Résumé de commande',
                OriginalPrice: 'Prix original',
                Savings: 'Réduction',
                Promo: 'Promo',
                ShippingFee: 'Frais de livraison',
                Tax: 'Tax',
                Total: 'Total',
                HavePromoCode: 'Avez-vous un code promo ?',
                Apply: 'Appliquer',
                SelectPaymentMethod: 'Sélectionnez une méthode de paiement',
                PayOnDelivery: 'Payez à la livraison',
                Or: 'Ou',
                CreditCard: 'Carte de crédit',
                ChooseStyle: 'Choisir le style',
                ChooseSize: 'Choisir le taille',
                Remove: 'Enlever',
                Category: 'Catégorie',
                Tag: 'Tag',
                Styles: 'Couleurs',
                Rating: 'Réputation',
                Stock: 'Stock',
                Price: 'Prix',
                Size: 'Taille',
                Quantity: 'Quantité',
                AddToCart: 'Ajouter au panier',
                ProductTags: 'Tags du produit',
                ProductDescription: 'Description du produit',
                Login: 'Connexion',
                Register: 'Inscription',
                YourEmail: 'Votre email',
                YourPassword: 'Votre mot de passe',
                DontHaveAnAccount: 'Vous n\'avez pas de compte ?',
                Name: 'Nom',
                ConfirmPassword: 'Confirmer le mot de passe',
                YourAddress: 'Votre adresse',
                YourPhone: 'Votre téléphone',
                YourPassword: 'Votre mot de passe',
                AlreadyHaveAnAccount: 'Vous avez déjà un compte ?',
                MyInformations: 'Mes informations',
                Favs: 'Favoris',
                MyFavs: 'Mes favoris',
                MyOrders: 'Mes commandes',
                ComingSoon: 'Bientôt disponible !',
                User: 'Utilisateur',
                Price: 'Prix',
                Products: 'Produits',
                Status: 'Statut',
                NoOrdersFound: 'Aucune commande trouvée',
                NoFavsFound: 'Aucun favoris trouvé',
                ShoppingNow: 'Acheter maintenant !',
                Pending: 'En attente',
                Processing: 'En cours de traitement',
                Shipped: 'Expédié',
                Completed: 'Terminé',
                Canceled: 'Annulé',
                Update: 'Mettre à jour',
                Reset: 'Réinitialiser',
                Location: 'Localisation',
                Email: 'Email',
                CallUs: 'Contactez-nous',
                ContactUs: 'Contactez-nous',
                FillOutTheFormBelowAndWeWillGetBackToYouAsSoonAsPossible: 'Remplissez le formulaire ci-dessous et nous vous répondrons sous peu.',
                SendMessage: 'Envoyer un message',
                Subject: 'Sujet',
                Message: 'Message',
                OurProducts: 'Nos produits',
                OurCategories: 'Nos catégories',
                FAQAndSupport: 'Nous contacter',
                TermsAndConditions: 'Conditions générales de vente',
                PrivacyPolicy: 'Politique de confidentialité',
                AllRightsReserved: 'Tous droits réservés',
                Filters: 'Filtres',
                NoProductsFound: 'Aucun produit trouvé',
                SubCategory: 'Sous-catégorie',
                Subcategories:'Sous-catégories',
                Search: 'Recherche',
                All: 'Tout',
                AppliedSuccessfully: 'Appliqué avec succès !',
                Image: 'Image',
                Actions: 'Actions',
                ForgotPassword: 'Mot de passe oublié',
                EnterEmailLink:'Entrez votre adresse e-mail et nous vous enverrons un code pour réinitialiser votre mot de passe',
                Send: 'Envoyer',
                EnterCodeRecived:'Entrez votre nouveau mot de passe et le code reçu',
                ChangePassword: 'Changer le mot de passe',
                didForgotPassword: 'avez-vous oublié votre mot de passe ?',
                forgotpassword: 'mot de passe oublié',
                forgotPasswordLink: 'mot de passe oublié',
            }
        }
    }
});

export default i18n;