<template>
    <footer>
        <div class="footerTop">
            <div class="socialLinks">
                <a href="https://www.facebook.com" target="_blank">
                    <i class="fa-brands fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com" target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.twitter.com" target="_blank">
                    <i class="fa-brands fa-twitter"></i>
                </a>
            </div>
            <div class="paimentMethods">
                <i class="fa-brands fa-cc-visa"></i>
                <i class="fa-brands fa-cc-mastercard"></i>
                <i class="fa-brands fa-cc-paypal"></i>
            </div>
        </div>
        <div class="footerMain">
            <div class="links">
                <router-link to="/products">
                    {{ $t('language.OurProducts') }}
                </router-link>
                <router-link to="/categories">
                    {{ $t('language.OurCategories') }}
                </router-link>
                <router-link to="/contact">
                    {{ $t('language.FAQAndSupport') }}
                </router-link>
            </div>
            <div class="links">
                <router-link v-for="(cat, index) in categories.slice(0, 3)" :key="index" :to="'/categories?catName=' + this.removeSpaces(cat.name)">
                    <span v-if="$i18n.locale == 'en'">{{cat.name}}</span>
                    <span v-if="$i18n.locale == 'fr'">{{cat.nameFr}}</span>
                    <span v-if="$i18n.locale == 'ar'">{{cat.nameAr}}</span>
                </router-link>
            </div>
            <div class="links">
                <router-link to="/terms">
                    {{ $t('language.TermsAndConditions') }}
                </router-link>
                <router-link to="/privacy">
                    {{ $t('language.PrivacyPolicy') }}
                </router-link>
            </div>
        </div>
        <div class="footerBottom">
            <p>WIMO &copy; 2024 - {{ $t('language.AllRightsReserved') }}</p>
        </div>
    </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'FooterComp',
    data() {
        return {
            
        }
    },
    methods: {
        ...mapActions(['getAllCategories']),
        removeSpaces(str) {
            return str.replace(/\s+/g, '-').toLowerCase()
        }
    },
    computed: {
      ...mapGetters(['categories'])  
    },
    async mounted() {
        await this.getAllCategories()
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/_variables.scss';

footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
    background-color: $primary-color;

    .footerTop {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom:1px solid white;
        padding: 1rem;
        width: 100%;

        .socialLinks {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10%;

            a {
                text-decoration: none;
                color: white;
                font-size: 1.5rem;
                margin: 0 0.5rem;
                transition: all 0.3s ease;
                &:hover {
                    color: $secondary-color;
                }
            }
        }

        .paimentMethods {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: white;
                font-size: 1.5rem;
                margin: 0 0.5rem;
            }
        }
    }

    .footerMain {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 1rem;

        .links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1rem;

            a {
                text-decoration: none;
                font-size: 1rem;
                color: white;
                font-weight: bold;
                margin: 0.5rem 0;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footerBottom {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top:1px solid white;
        width: 100%;
        padding: 1rem;
        background-color: $primary-color;

        p {
            color: white;
            font-size: 1rem;
        }
    }
}
</style>