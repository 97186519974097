import { createStore } from 'vuex'
import Cart from './modules/Cart';
import Loader from './modules/Loader';
import Categories from './modules/Categories';
import Auth from './modules/Auth';
import Products from './modules/Products';
import Order from './modules/Order';
import User from './modules/User';
import SubCategories from './modules/SubCategories';
import Banner from './modules/Banner';
import Message from './modules/Message';
import PromoCode from './modules/PromoCode';
import ProductAds from './modules/ProductAds';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Cart,
    Loader,
    Categories,
    Auth,
    Products,
    Order,
    User,
    SubCategories,
    Banner,
    Message,
    PromoCode,
    ProductAds
  }
})
