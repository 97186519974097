import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '../layouts/DashboardLayout.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import('../views/ProductsView.vue')
      },
      {
        path: '/categories',
        name: 'Categories',
        component: () => import('../views/CategoriesView.vue')
      },
      {
        path: '/categories/:id',
        name: 'Category',
        component: () => import('../views/CategoryView.vue')
      },
      {
        path: '/products/:id',
        name: 'Product',
        component: () => import('../views/ProductView.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginView.vue'),
        meta: {
          needNoAuthentification: true
        }
      },
      {
        path: '/login-admin',
        name: 'LoginAdmin',
        component: () => import('../views/LoginAdminView.vue'),
        meta: {
          needNoAuthentification: true
        }
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/RegisterView.vue'),
        meta: {
          needNoAuthentification: true
        }
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () => import('../views/CartView.vue'),
        meta: {
          needAuthentification: false
        }
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/ContactView.vue')
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/ProfileView.vue'),
        meta: {
          needAuthentification: true
        }
      },
      {
        path: '/terms',
        name: 'Terms',
        component: () => import('../views/TermsView.vue')
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('../views/PrivacyView.vue')
      },
      {
        path: '/fail-payment',
        name: 'FailPayment',
        component: () => import('../views/FailPaymentView.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPasswordView.vue'),
        meta: {
          needNoAuthentification: true
        }
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'AdminLayout',
    component: () => import('../layouts/DashboardLayout.vue'),
    meta: {
      needAuthentification: true,
      needAdmin: true
    },
    children: [
      {
        path: '',
        name: 'Admindashboard',
        component: () => import('../views/BackOffice/DashboardView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'categories',
        name: 'AdminCategories',
        component: () => import('../views/BackOffice/ManagCategoriesView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'subcategories',
        name: 'AdminSubCategories',
        component: () => import('../views/BackOffice/ManagSubCategoriesView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'promocodes',
        name: 'AdminPromocodes',
        component: () => import('../views/BackOffice/ManagPromocodesView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'products',
        name: 'AdminProducts',
        component: () => import('../views/BackOffice/ManagProductsView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'orders',
        name: 'AdminOrders',
        component: () => import('../views/BackOffice/ManagOrdersView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'banners',
        name: 'AdminBanners',
        component: () => import('../views/BackOffice/ManagBannersView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'productads',
        name: 'AdminProductAds',
        component: () => import('../views/BackOffice/ManagProductAdsView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'messages',
        name: 'AdminMessages',
        component: () => import('../views/BackOffice/ManagMessagesView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: () => import('../views/BackOffice/ManagUsersView.vue'),
        meta: {
          needAuthentification: true,
          needAdmin: true
        },
      },
    ]
  },
  // any route not defined above will be redirected to the home view
  {
    path: '/:pathMatch(.*)*',
    redirect:'/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth'
        }
      }
      return { top: 0, behavior: 'instant'}
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.needAuthentification) && to.matched.some(record => record.meta.needAdmin)) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!localStorage.getItem('token')) {
      next({
        name: 'LoginAdmin',
        query: { redirect: to.fullPath }
      });
    } else {
        if(user.role == 'admin'){
          next();
        }else{
          next({
            name: 'LoginAdmin',
            query: { redirect: to.fullPath }
          });
        } 
    }
  }else if (to.matched.some(record => record.meta.needAuthentification) && !to.matched.some(record => record.meta.needAdmin)) {
    if (!localStorage.getItem('token')) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.needNoAuthentification)) {
    if (localStorage.getItem('token')) {
      next({
        name: 'Home'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
