import Swal from 'sweetalert2'


const state = {
    loaderState: false
};

const getters = {
    loaderState: (state) => state.loaderState
};

const mutations = {
    setLoaderState: (state, loaderState) => (state.loaderState = loaderState)
};

const actions = {
    async startLoading({ commit }) {
        commit('setLoaderState', true);
    },
    async stopLoading({ commit }) {
        commit('setLoaderState', false);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
  };