<template>
    <nav class="Sidebar">
        <div class="Logo">
            <h1>WIMO <span> Store </span></h1>
        </div>
        <div class="links">
            <button v-for="(link, index) in links" :key="index" @click="handleOpenPage(link.path)" :class="this.currentRouteUrl == link.path ? 'active' : ''">
                <i :class="link.icon"></i> {{ link.name }}
            </button>
            <button @click="handleLogout()">
                <i class="fa-solid fa-sign-out"></i> {{ $t('language.Logout') }}
            </button>
        </div>
    </nav>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'SidebarComp',
    data() {
        return {
            currentRouteUrl: '',
            links: [
                {
                    name:'Dashboard',
                    icon: 'fa-solid fa-gauge',
                    path: '/dashboard'
                },
                {
                    name: 'Categories',
                    icon: 'fa-solid fa-icons',
                    path: '/dashboard/categories',
                },
                {
                    name: 'Sub-Categories',
                    icon: 'fa-solid fa-icons',
                    path: '/dashboard/subcategories',
                },
                {
                    name: 'Promo Codes',
                    icon: 'fa-solid fa-percent',
                    path: '/dashboard/promocodes',
                },
                {
                    name: 'Products',
                    icon: 'fa-solid fa-bag-shopping',
                    path: '/dashboard/products',
                },
                {
                    name: 'Orders',
                    icon: 'fa-solid fa-star',
                    path: '/dashboard/orders',
                },
                {
                    name: 'Banners',
                    icon: 'fa-solid fa-image',
                    path: '/dashboard/banners',
                },
                {
                    name: 'Product Ads',
                    icon: 'fa-solid fa-ad',
                    path: '/dashboard/productads',
                },
                {
                    name: 'Users',
                    icon: 'fa-solid fa-user',
                    path: '/dashboard/users',
                },
                {
                    name: 'Messages',
                    icon: 'fa-solid fa-envelope',
                    path: '/dashboard/messages',
                }
            ]
        }
    },
    components: {
    },
    methods: {
        ...mapActions(['logout']),
        handleOpenPage(path) {
            this.$router.push(path);
        },
        async handleLogout() {
            let res = await this.logout();
            if (res) {
                this.$router.push('/login');
            }else {
                localStorage.removeItem('token');
                this.$router.push('/login');
            }
        }
    },
    computed: {
    },
    mounted() {
        // get current route url
        this.currentRouteUrl = this.$route.path;
    },
    watch: {
        $route(to, from) {
            this.currentRouteUrl = to.path;
        }
    }
}
</script>
<style lang="scss">
@import '../assets/_variables.scss';

.Sidebar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 15%;
    background-color: $secondary-color;
    color: #fff;
    z-index: 1000;

    .Logo {
        padding: 10px 0;

        h1 {
            color: #fff;
            font-size: 2rem;
        }
    }
    
    .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        width: 100%;
        
        button {
            width: 100%;
            padding: 8px;
            border: none;
            background-color: transparent;
            color: #fff;
            font-size: 1rem;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: background-color 0.3s;
            margin-top: 1rem;

            i {
                margin-right: 10px;
            }

            &:hover {
                background-color: $primary-color;
            }
        }

        button.active {
            background-color: $primary-color;
        }
        
    }
}
</style>