<template>
    <div v-if="isShow">
        <button @click="scrollToTop()" class="up-button">
            <i class="fas fa-arrow-up"></i>
        </button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                this.isShow = true
            } else {
                this.isShow = false
            }
        })
    },
}
</script>
<style lang="scss" scoped>
@import '../assets/_variables.scss';

button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


    i {
        font-size: 1.3rem;
    }

    &:hover {
        background-color: $secondary-color;
    }
}
</style>