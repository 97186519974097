<template>
    <div class="homePage">
        <main>
            <div class="banner" @mouseover="stopSlideshow" @mouseleave="startSlideshow">
                <img class="bannerImg" :src="getImageUrl(currentBanner)" @click="openProducts()"/>
            </div>
            <div class="ads" v-if="!HomeAdsStatus">
                <a :href="ad.url" target="_blank" v-for="(ad, index) in this.productads.slice(0, 3)" :key="index">
                    <img :src="getImageUrlAds(ad)" alt="ad1">
                </a>
            </div>
            <div class="ads" v-if="!HomeAdsStatus">
                <a :href="ad.url" target="_blank" v-for="(ad, index) in this.productads.slice(4, 7)" :key="index">
                    <img :src="getImageUrlAds(ad)" alt="ad1">
                </a>
            </div>
            <div class="Section" id="bestSellers">
                <div class="SectionHeader">
                    <h1>{{ $t('language.BestSellers') }}</h1>
                    <router-link to="/products">{{ $t('language.More') }}...</router-link>
                </div>
                <div class="SectionBody">
                    <div class="product" v-for="(product,index) in this.bestSellers" :key="index" >
                        <img :src="getProductImgUrl(product)" alt="" @click="openProduct(product._id)">
                        <h3 v-if="$i18n.locale == 'en'">{{truncateTitle(product.name, 10)}}</h3>
                        <h3 v-if="$i18n.locale == 'fr'">{{truncateTitle(product.nameFr, 10)}}</h3>
                        <h3 v-if="$i18n.locale == 'ar'">{{truncateTitle(product.nameAr, 10)}}</h3>
                        <div class="productFooter">
                            <template v-if="$i18n.locale == 'en'">
                                <p v-if="discount == 0">${{ product.price }}</p>
                                <p v-else>${{ (product.price - (product.price * product.discount / 100)).toFixed(2) }}</p>
                            </template>
                            <template v-else-if="$i18n.locale == 'fr'">
                                <p v-if="discount == 0">€{{ product.priceFr }}</p>
                                <p v-else>€{{ (product.priceFr - (product.priceFr * product.discount / 100)).toFixed(2) }}</p>
                            </template>
                            <template v-else-if="$i18n.locale == 'ar'">
                                <p v-if="discount == 0">{{ product.priceAr }} Dt</p>
                                <p v-else>{{ (product.priceAr - (product.priceAr * product.discount / 100)).toFixed(2) }} Dt</p>
                            </template>
                            <button :class="checkProductInFavs(product._id) ? 'btnRed' : '' " @click="handleAddToFavs(product._id)">
                                <i class="fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Section" id="NewArrivals">
                <div class="SectionHeader">
                    <h1>{{ $t('language.NewArrivals') }}</h1>
                    <router-link to="/products">{{ $t('language.More') }}...</router-link>
                </div>
                <div class="SectionBody">
                    <div class="product" v-for="(product,index) in this.newArrivals" :key="index" >
                        <img :src="getProductImgUrl(product)" alt="" @click="openProduct(product._id)">
                        <h3 v-if="$i18n.locale == 'en'">{{truncateTitle(product.name, 10)}}</h3>
                        <h3 v-if="$i18n.locale == 'fr'">{{truncateTitle(product.nameFr, 10)}}</h3>
                        <h3 v-if="$i18n.locale == 'ar'">{{truncateTitle(product.nameAr, 10)}}</h3>
                        <div class="productFooter">
                            <template v-if="$i18n.locale == 'en'">
                                <p v-if="discount == 0">${{ product.price }}</p>
                                <p v-else>${{ (product.price - (product.price * product.discount / 100)).toFixed(2) }}</p>
                            </template>
                            <template v-else-if="$i18n.locale == 'fr'">
                                <p v-if="discount == 0">€{{ product.priceFr }}</p>
                                <p v-else>€{{ (product.priceFr - (product.priceFr * product.discount / 100)).toFixed(2) }}</p>
                            </template>
                            <template v-else-if="$i18n.locale == 'ar'">
                                <p v-if="discount == 0">{{ product.priceAr }} Dt</p>
                                <p v-else>{{ (product.priceAr - (product.priceAr * product.discount / 100)).toFixed(2) }} Dt</p>
                            </template>
                            <button :class="checkProductInFavs(product._id) ? 'btnRed' : '' " @click="handleAddToFavs(product._id)">
                                <i class="fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Section" id="DiscountDeals">
                <div class="SectionHeader">
                    <h1>{{ $t('language.DiscountDeals') }}</h1>
                    <router-link to="/products">{{ $t('language.More') }}...</router-link>
                </div>
                <div class="SectionBody">
                    <div class="product" v-for="(product,index) in this.discountDeals" :key="index" >
                        <img :src="getProductImgUrl(product)" alt="" @click="openProduct(product._id)">
                        <h3 v-if="$i18n.locale == 'en'">{{truncateTitle(product.name, 10)}}</h3>
                        <h3 v-if="$i18n.locale == 'fr'">{{truncateTitle(product.nameFr, 10)}}</h3>
                        <h3 v-if="$i18n.locale == 'ar'">{{truncateTitle(product.nameAr, 10)}}</h3>
                        <div class="productFooter">
                            <template v-if="$i18n.locale == 'en'">
                                <p class="OldPrice" v-if="product.discount > 0">${{ product.price }}</p>
                                <p class="NewPrice">{{ (product.price - (product.price * product.discount / 100)).toFixed(2) }}</p>
                            </template>
                            <template v-else-if="$i18n.locale == 'fr'">
                                <p class="OldPrice" v-if="product.discount > 0">€{{ product.priceFr }}</p>
                                <p class="NewPrice">€{{ (product.priceFr - (product.priceFr * product.discount / 100)).toFixed(2) }}</p>
                            </template>
                            <template v-else-if="$i18n.locale == 'ar'">
                                <p class="OldPrice" v-if="product.discount > 0">{{ product.priceAr }} Dt</p>
                                <p class="NewPrice">{{ (product.priceAr - (product.priceAr * product.discount / 100)).toFixed(2) }} Dt</p>
                            </template>
                            <button :class="checkProductInFavs(product._id) ? 'btnRed' : '' " @click="handleAddToFavs(product._id)">
                                <i class="fa-solid fa-heart"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'HomeView',
    data() {
        return {
            bestSellers: [],
            newArrivals: [],
            discountDeals: [],
            currentBanner: '',
            slideshowInterval: null,
            AllAds: [],
            HomeAdsStatus: true,
            TagsList: []
        }
    },
    methods: {
        ...mapActions(['getAllProducts', 'getAllBanners', 'AddToCart', 'getAllCategories', 'addFavs', 'deleteFavs', 'getAllProductAds']),
        openHome() {
            this.$router.push('/');
        },
        openDropDown(id) {
            const dropDown = document.getElementById(id);
            console.log(dropDown.style.display);
            dropDown.style.display = dropDown.style.display === 'none' ? 'flex' : 'none';

            // Close other dropdowns
            const dropDowns = document.querySelectorAll('.DropDownList');
            dropDowns.forEach(dropDown => {
                if (dropDown.id !== id) {
                    dropDown.style.display = 'none';
                }
            });
        },
        truncateTitle(title, length) {
            if (title.length > length) {
                return title.slice(0, length) + '...';
            }
            return title;
        },
        openProduct(id) {
            this.$router.push(`/products/${id}`);
        },
        openProducts() {
            this.$router.push(`/products`);
        },
        getProductImgUrl(product) {
            return process.env.VUE_APP_SERVERBASEURL + product.coverImage;
        },
        getImageUrl(image) {
            return process.env.VUE_APP_SERVERBASEURL + image;
        },
        getImageUrlAds(ad) {
            return process.env.VUE_APP_SERVERBASEURL + ad.image;
        },
        startSlideshow() {
            this.slideshowInterval = setInterval(this.nextBanner, 2000);
        },
        stopSlideshow() {
            clearInterval(this.slideshowInterval);
            this.slideshowInterval = null;
        },
        nextBanner() {
            const currentIndex = this.banners.findIndex(banner => banner.imageUrl === this.currentBanner);
            const nextIndex = (currentIndex + 1) % this.banners.length;
            this.currentBanner = this.banners[nextIndex].imageUrl;
        },
        async handleAddToCart(product) {
            let payload = {...product, quantity: 1, size: product.sizes[0], style: 'default'};
            await this.AddToCart({ payload: payload, router: this.$router});
        },
        checkScreenResolution() {
            if (window.innerWidth <= 768) {
                this.HomeAdsStatus = false;
            } else {
                this.HomeAdsStatus = true;
            }
        },
        async handleAddToFavs(productId) {
            const user = JSON.parse(localStorage.getItem('user'));
            console.log(productId);

            if (user && user.favs) {
                if (user.favs.includes(productId)) {
                    user.favs = user.favs.filter(fav => fav !== productId);
                    localStorage.setItem('user', JSON.stringify(user));
                    await this.deleteFavs({ user });
                } else {
                    user.favs.push(productId);
                    localStorage.setItem('user', JSON.stringify(user));
                    await this.addFavs({ user });
                }
            } else if (user && !user.favs) {
                user.favs = [productId];
                localStorage.setItem('user', JSON.stringify(user));
                await this.addFavs({ user });
            } else {
                this.$router.push('/login');
            }
        },
        checkProductInFavs(productId) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.favs) {
                return user.favs.includes(productId);
            }
            return false;
        }
    },
    computed: {
        ...mapGetters(['products', 'banners', 'categories', 'productads'])
    },
    async mounted() {
        await this.getAllProducts();
        await this.getAllCategories();
        await this.getAllProductAds();
        console.log(this.products);

        this.products.forEach(product => {
            product.tags.forEach(tag => {
                if (!this.TagsList.includes(tag)) {
                    this.TagsList.push(tag);
                }
            });
        });

        // get top 16 best sellers products field selledCount
        this.bestSellers = await this.products.sort((a, b) => b.selledCount - a.selledCount).slice(0, 16);

        // get top 16 new arrivals products field createdAt
        this.newArrivals = await this.products.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 16);

        // get top 16 discount deals products field discount check if has discount or discount != 0
        this.discountDeals = await this.products.filter(product => product.discount != 0).slice(0, 16);

        await this.getAllBanners();
        this.currentBanner = this.banners[0].imageUrl;
        this.startSlideshow();

        this.AllAds = this.categories.concat(this.SubCategories);

        this.checkScreenResolution();
        window.addEventListener('resize', this.checkScreenResolution);
    },
    components: {
    },
    beforeDestroy() {
        this.stopSlideshow();
        window.removeEventListener('resize', this.checkScreenResolution);
    },
    watch: {
        $route(to, from) {
            this.checkScreenResolution();
        },
        products() {
            this.products.forEach(product => {
                product.tags.forEach(tag => {
                    if (!this.TagsList.includes(tag)) {
                        this.TagsList.push(tag);
                    }
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/_variables.scss';

.homePage {
    display: flex;
    gap: 1rem;
    padding: 0rem;
    margin-top: 1rem;
    justify-content: center;
    flex-wrap: wrap;

    .Navbar {
        display: none;

        .dropdownHolder {
            display: none;
        }
    }

    main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.2rem;
        border-radius: 5px;

        .banner {
            width: 100%;
            height: 200px;
            overflow: hidden;
            border-radius: 5px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .ads {
            display: flex;
            // justify-content: space-around;
            gap: 0.2rem;
            width: 100%;

            a {
                width: 33%;
                height: 200px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }

        .Section {
            width: 100%;
            border-radius: 5px;
            background-color: white;
            padding: 0rem;
            border: 1px solid #ccc;
            .SectionHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                border-left: 4px solid $primary-color;
                border-bottom: 1px solid $primary-color;
                padding-left: 1rem;
                margin-bottom: 1rem;

                h1 {
                    font-size: 1.2rem;
                    color: $primary-color;
                    font-weight: bold;
                }

                a {
                    text-decoration: none;
                    color: $primary-color;
                    font-size: 1rem;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    background-color: white;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: $secondary-color;
                        text-decoration: underline;
                    }
                }
            }

            .SectionBody {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                gap: 0.4rem;

                .product {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // gap: 0.5rem;
                    padding: 0.5rem;
                    border-radius: 5px;
                    background-color: white;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    img {
                        width: 100%;
                        height: 170px;
                        object-fit: cover;
                        border-radius: 5px;
                    }

                    h3 {
                        font-size: 1rem;
                        color: black;
                        width: 100%;
                        text-align: left;
                        padding: 2px 5px;
                    }

                    .productFooter {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 0 5px;

                        p {
                            font-size: 0.7rem;
                            color: black;
                        }
    
                        button {
                            padding: 0.3rem 0.7rem;
                            border: none;
                            border-radius: 5px;
                            background-color: rgb(67, 67, 157);
                            color: white;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
                            font-size: 0.8rem;
    
                            span {
                                margin-left: 0.4rem;
                            }
    
                            &:hover {
                                background-color: $secondary-color;
                            }
                        }

                        .btnRed {
                            background-color: rgb(221, 0, 0);
                        }
    
                        .OldPrice {
                            text-decoration: line-through;
                            color: red;
                        }
    
                        .NewPrice {
                           font-weight: bold;
                           animation: blankColor 1s linear infinite;
                        }
                    }


                    &:hover {
                        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
                    }
                }
            }
        }
    }
}

@keyframes blankColor {
    0% {
        color: $secondary-color;
    }
    50% {
        color: $primary-color;
    }
    100% {
        color: $secondary-color;
    }
}

@media screen and (max-width: 768px) {
    .homePage {
        flex-direction: column;
        gap: 0.2rem;
        margin-top: 0;

        .Navbar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .dropdownHolder {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.5rem;
                background-color: white;
                color: $primary-color;
                font-size: 0.7rem;
                font-weight: bold;
                transition: all 0.3s ease-in-out;
                position: relative;

                i {
                    font-size: 0.7rem;
                }

                .DropDownList {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 140px;
                    background-color: white;
                    z-index: 100;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 0.5rem;
                    padding: 0.5rem;

                    a {
                        display: block;
                        padding: 1rem;
                        border-radius: 5px;
                        transition: all 0.3s ease-in-out;
                        font-size: 0.8rem;

                        i {
                            font-size: 0.8rem;
                        }

                        &:hover {
                            background-color: $primary-color;
                            color: white;
                        }
                    }
                }


                .LastList {
                    left: unset;
                    right: 0;
                }
            }
        }

        main {
            width: 100%;

            .banner {
                display: none;
                height: 100px;
            }

            .ads {
                display: flex;
                gap: 0.2rem;
                width: 100%;

                a {
                    width: 33%;
                    height: 100px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                    }
                }
            }
            
            .Section {
                padding: 0.2rem;
                .SectionHeader {
                    h1 {
                        font-size: 1rem;
                    }

                    a {
                        font-size: 0.8rem;
                    }
                }

                .SectionBody {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 0.2rem;
                    row-gap: 0.2rem;

                    .product {
                        padding: 0rem;

                        img {
                            height: 150px;
                        }

                        h3 {
                            font-size: 0.7rem;
                            font-weight: bold;
                        }

                        .productFooter {
                            p {
                                font-size: 0.6rem;
                            }

                            button {
                                padding: 0.2rem 0.5rem;
                                font-size: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 374px) {
    .homePage {
        flex-direction: column;
        gap: 0.2rem;
        margin-top: 0;

        main {
            width: 100%;

            .banner {
                height: 100px;
            }

            .ads {
                display: flex;
                gap: 0.2rem;
                width: 100%;

                a {
                    width: 33%;
                    height: 100px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                    }
                }
            }
            
            .Section {
                padding: 0.2rem;
                .SectionHeader {
                    h1 {
                        font-size: 1rem;
                    }

                    a {
                        font-size: 0.8rem;
                    }
                }

                .SectionBody {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 0.2rem;
                    row-gap: 0.2rem;

                    .product {
                        padding: 0rem;

                        img {
                            height: 150px;
                        }

                        h3 {
                            font-size: 0.7rem;
                            font-weight: bold;
                        }

                        .productFooter {
                            p {
                                font-size: 0.6rem;
                            }

                            button {
                                padding: 0.2rem 0.5rem;
                                font-size: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>