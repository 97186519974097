import Swal from "sweetalert2"

const state = {
    categories: [],
    currentCategory: {},
}

const getters = {
    categories: state => state.categories,
    currentCategory: state => state.currentCategory,
}

const mutations = {
    setCategories(state, categories) {
        state.categories = categories;
        console.log('setCategories');
    },
    setCurrentCategory(state, category) {
        state.currentCategory = category
    },
}

const actions = {
    async getAllCategories({ commit, dispatch }) {
        console.log('getAllCategories');
        
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/categories', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setCategories', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getCategory({ commit, dispatch }, id) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/categories/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setCurrentCategory', data.category);
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async addCategory({ commit, dispatch }, category) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(category)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Category added successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                await dispatch('getAllCategories');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updateCategory({ commit, dispatch }, category) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/categories/' + category._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(category)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Category updated successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                
                dispatch('stopLoading');
                await dispatch('getAllCategories');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteCategory({ commit, dispatch }, category) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/categories/' + category._id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });
    
            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Category Deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                dispatch('stopLoading');
                await dispatch('getAllCategories');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}

