import Swal from "sweetalert2"

const state = {
    productads: [],
    currentProductAd: {},
}

const getters = {
    productads: state => state.productads,
    currentProductAd: state => state.currentProductAd,
}

const mutations = {
    setProductAds: (state, productads) => state.productads = productads,
    setCurrentProductAd: (state, productad) => state.currentProductAd = productad,
}

const actions = {
    async getAllProductAds({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/productads', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setProductAds', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getProductAd({ commit, dispatch }, id) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/productads/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                await commit('setCurrentProductAd', data.ProductAd);
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return null;
        }
    },
    async addProductAd({ commit, dispatch }, productad) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/productads', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(productad)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'ProductAd added successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                await dispatch('getAllProductAds');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updateProductAd({ commit, dispatch }, productad) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/productads/' + productad._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(productad)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'ProductAd updated successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                await dispatch('getAllProductAds');
            }else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
            }

        } catch (err) {
            dispatch('getAllProductAds');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });

            dispatch('stopLoading');
        }
    },
    async deleteProductAd({ commit, dispatch }, productad) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/productads/' + productad._id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });
    
            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'ProductAd Deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                
                dispatch('stopLoading');
                await dispatch('getAllProductAds');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}

