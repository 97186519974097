import Swal from "sweetalert2";

const state = {
    Promocodes: [],
    currentPromocode: {
        name: "",
        email: "",
        subject: "",
        Promocode: "",
    },
};

const getters = {
    Promocodes: (state) => state.Promocodes,
    currentPromocode: (state) => state.currentPromocode,
};

const mutations = {
    setPromocodes(state, Promocodes) {
        state.Promocodes = Promocodes;
    },
    setCurrentPromocode(state, Promocode) {
        state.currentPromocode = Promocode;
    },
};

const actions = {
    async getAllPromocodes({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/Promocodes', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setPromocodes', data);
            }else {
                dispatch('stopLoading');
                console.log(data.Promocode);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.Promocode,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getPromocode({ commit, dispatch }, id) {
        console.log(id);
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/Promocodes/" + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            let data = await response.json();

            if (data.status == "success") {

                dispatch("stopLoading");
                await commit("setCurrentPromocode", data.promocode);
            } else {
                
                dispatch("stopLoading");
                console.log(data.promocode);
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.promocode,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async addPromocode({ commit, dispatch }, Promocode) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/promocodes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
                body: JSON.stringify(Promocode),
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Promocode added successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                await dispatch("getAllPromocodes");
            } else {
                dispatch("stopLoading");
                console.log(data.Promocode);
                Swal.fire({
                    title: "Error",
                    text: data.Promocode,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.Promocode,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deletePromocode({ commit, dispatch }, Promocode) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/Promocodes/" + Promocode._id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Promocode deleted successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                await dispatch("getAllPromocodes");
            } else {
                dispatch("stopLoading");
                console.log(data.Promocode);
                Swal.fire({
                    title: "Error",
                    text: data.Promocode,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.Promocode,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updatePromocode({ commit, dispatch }, Promocode) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/Promocodes/" + Promocode._id, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
                body: JSON.stringify(Promocode),
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Promocode updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                await dispatch("getAllPromocodes");
            } else {
                dispatch("stopLoading");
                console.log(data.Promocode);
                Swal.fire({
                    title: "Error",
                    text: data.Promocode,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.Promocode,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getPromoCodeByCode({ commit, dispatch }, code) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/Promocodes/code/" + code, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            let data = await response.json();

            if (data.status == "success") {
                dispatch("stopLoading");
                commit("setCurrentPromocode", data.promocode);
            } else {
                dispatch("stopLoading");
                console.log(data.promocode);
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.promocode,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};