import Swal from "sweetalert2"

const state = {
    banners: [],
    currentBanner: {},
}

const getters = {
    banners: state => state.banners,
    currentBanner: state => state.currentBanner,
}

const mutations = {
    setBanners: (state, banners) => state.banners = banners,
    setCurrentBanner: (state, banner) => state.currentBanner = banner,
}

const actions = {
    async getAllBanners({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/banners', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setBanners', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getBanner({ commit, dispatch }, id) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/banners/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                await commit('setCurrentBanner', data.Banner);
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return null;
        }
    },
    async addBanner({ commit, dispatch }, banner) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/banners', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(banner)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Banner added successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                await dispatch('getAllBanners');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updateBanner({ commit, dispatch }, banner) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/banners/' + banner._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(banner)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Banner updated successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                await dispatch('getAllBanners');
            }else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
            }

        } catch (err) {
            dispatch('getAllBanners');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });

            dispatch('stopLoading');
        }
    },
    async deleteBanner({ commit, dispatch }, banner) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/banners/' + banner._id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });
    
            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Banner Deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                
                dispatch('stopLoading');
                await dispatch('getAllBanners');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}

