import Swal from "sweetalert2";

const state = {
    products: [],
    currentProduct: {
        _id: "",
        name: "",
        price: 0,
        description: "",
        image: "",
        category: "",
        stock: 0,
        discount: 0,
    },
};

const getters = {
    products: (state) => state.products,
    currentProduct: (state) => state.currentProduct,
};

const mutations = {
    setProducts(state, products) {
        state.products = products;
    },
    setCurrentProduct(state, product) {
        state.currentProduct = product;
    },
};

const actions = {
    async getAllProducts({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/products', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setProducts', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getProduct({ commit, dispatch }, id) {
        console.log(id);
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/products/" + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            let data = await response.json();

            if (data.status == "success") {
                dispatch("stopLoading");
                commit("setCurrentProduct", data.product);
            } else {
                dispatch("stopLoading");
                console.log(data.message);
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async addProduct({ commit, dispatch }, product) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/products", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
                body: JSON.stringify(product),
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Product added successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                await dispatch("getAllProducts");
            } else {
                dispatch("stopLoading");
                console.log(data.message);
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updateProduct({ commit, dispatch }, product) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/products/" + product._id, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
                body: JSON.stringify(product),
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Product updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                dispatch("getAllProducts");
            } else {
                dispatch("stopLoading");
                console.log(data.message);
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteProduct({ commit, dispatch }, product) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/products/" + product._id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Product deleted successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                dispatch("getAllProducts");
            } else {
                dispatch("stopLoading");
                console.log(data.message);
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};