<template>
    <div class="main">
        <loader-comp v-if="this.loaderState"/>
        <UpButton />
        <NavbarComp/>
        <div class="DefaultLayout">
            <HomeSidebar v-if="HomeAdsStatus" />
            <div class="Navbar" v-else>
                <div class="dropdownHolder">
                    <div class="btnDropDown" @click="openHome()">
                        <i class="fa-solid fa-home"></i>
                        {{ $t('language.Home') }}
                    </div>
                </div>
                <div class="dropdownHolder">
                    <div class="btnDropDown" @click="openDropDown('dropDown1')">
                        <i class="fa-solid fa-box"></i>
                        {{ $t('language.Products') }}
                    </div>
                    <div class="DropDownList" id="dropDown1">
                        <router-link :to="{ name: 'Home', hash: '#bestSellers' }">
                            {{ $t('language.BestSellers') }}
                        </router-link>
                        <router-link :to="{ name: 'Home', hash: '#NewArrivals' }">
                            {{ $t('language.NewArrivals') }}
                        </router-link>
                        <router-link :to="{ name: 'Home', hash: '#DiscountDeals' }">
                            {{ $t('language.DiscountDeals') }}
                        </router-link>
                    </div>
                </div>
                <div class="dropdownHolder">
                    <div class="btnDropDown" @click="openDropDown('dropDown2')">
                        <i class="fa-solid fa-layer-group"></i>
                        {{ $t('language.Categories') }}
                    </div>
                    <div class="DropDownList" id="dropDown2">
                        <router-link :to="'/categories?catName=' + this.removeSpaces(cat.name)" v-for="(cat, index) in this.categories.slice(0, 4)" :key="index">
                            <span v-if="$i18n.locale == 'en'">{{cat.name}}</span>
                            <span v-if="$i18n.locale == 'fr'">{{cat.nameFr}}</span>
                            <span v-if="$i18n.locale == 'ar'">{{cat.nameAr}}</span>
                        </router-link>
                    </div>
                </div>
                <div class="dropdownHolder">
                    <div class="btnDropDown" @click="openDropDown('dropDown3')">
                        <i class="fa-solid fa-layer-group"></i>
                        {{ $t('language.Tags') }}
                    </div>
                    <div class="DropDownList LastList" id="dropDown3">
                        <router-link :to="'/products?tagName=' + tag" v-for="(tag, index) in TagsList.slice(0, 4)" :key="index">
                            {{tag}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="routerView">
                <router-view />
            </div>
            <HomeAdsVue v-if="HomeAdsStatus && currentRoute == '/'" />
        </div>
        <FooterComp/>
    </div>
</template>
<script>
import NavbarComp from '../components/NavbarComp.vue';
import HomeSidebar from '../views/HomeComps/HomeSidebar.vue';
import HomeAdsVue from '../views/HomeComps/HomeAds.vue';
import FooterComp from '../components/FooterComp.vue';
import UpButton from '../components/UpButton.vue';
import LoaderComp from '../components/LoaderComp.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            HomeAdsStatus: true,
            currentRoute: this.$route.path,
            TagsList: []
        }
    },
    components: {
        NavbarComp,
        FooterComp,
        UpButton,
        LoaderComp,
        HomeSidebar,
        HomeAdsVue
    },
    computed: {
        ...mapGetters(['loaderState', 'products', 'banners', 'categories', 'productads'])
    },
    methods: {
        ...mapActions(['getAllProducts', 'getAllCategories']),
        openHome() {
            window.location.href = '/';
        },
        openDropDown(id) {
            const dropDown = document.getElementById(id);
            console.log(dropDown.style.display);
            dropDown.style.display = dropDown.style.display === 'none' ? 'flex' : 'none';

            // Close other dropdowns
            const dropDowns = document.querySelectorAll('.DropDownList');
            dropDowns.forEach(dropDown => {
                if (dropDown.id !== id) {
                    dropDown.style.display = 'none';
                }
            });
        },
        checkScreenResolution() {
            if (window.innerWidth <= 768) {
                this.HomeAdsStatus = false;
            } else {
                this.HomeAdsStatus = true;
            }
        },
        removeSpaces(str) {
            return str.replace(/\s+/g, '-').toLowerCase()
        }
    },
    async mounted() {
        await this.getAllProducts();
        await this.getAllCategories();

        this.products.forEach(product => {
            product.tags.forEach(tag => {
                if (!this.TagsList.includes(tag)) {
                    this.TagsList.push(tag);
                }
            });
        });

        this.checkScreenResolution();
        window.addEventListener('resize', this.checkScreenResolution);
        window.addEventListener('click', (e) => {
            if (!e.target.classList.contains('btnDropDown')) {
                const dropDowns = document.querySelectorAll('.DropDownList');
                dropDowns.forEach(dropDown => {
                    dropDown.style.display = 'none';
                });
            }
        });
        this.currentRoute = this.$route.path;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenResolution);
    },
    watch: {
        $route(to, from) {
            this.checkScreenResolution();
            this.currentRoute = to.path;
            // display none all dropdowns
            const dropDowns = document.querySelectorAll('.DropDownList');
            dropDowns.forEach(dropDown => {
                dropDown.style.display = 'none';
            });
        },
        products() {
            this.products.forEach(product => {
                product.tags.forEach(tag => {
                    if (!this.TagsList.includes(tag)) {
                        this.TagsList.push(tag);
                    }
                });
            });
        }
    }
}
</script>
<style lang="scss"> 
@import '../assets/variables';

.DefaultLayout {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0rem 1rem;
    margin-top: 1rem;
    width: 100%;

    .Navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dropdownHolder {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            background-color: white;
            color: $primary-color;
            font-size: 0.7rem;
            font-weight: bold;
            transition: all 0.3s ease-in-out;
            position: relative;

            i {
                font-size: 0.7rem;
            }

            .DropDownList {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                width: 140px;
                background-color: white;
                z-index: 100;
                align-items: flex-start;
                flex-direction: column;

                a {
                    display: block;
                    padding: 0.5rem;
                    transition: all 0.3s ease-in-out;
                    font-size: 0.8rem;
                    border-bottom: 1px solid $primary-color;
                    width: 100%;
                    font-weight: 500;

                    i {
                        font-size: 0.8rem;
                    }

                    &:hover {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }


            .LastList {
                left: unset;
                right: 0;
            }
        }
    }

    .routerView {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .DefaultLayout {
        margin-top: 0;
        flex-direction: column;
        gap: 0;
    }
    
}
</style>