import Swal from 'sweetalert2'
import i18n from '@/i18n';

const state = {
    cart: [],
    cartLength: 0
};

const getters = {
    cart: (state ) => {
        let items = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : state.cart;
        state.cart = items;
        return state.cart;
    },
    cartLength: (state) => {
        let total = localStorage.getItem('cartTotal') ? JSON.parse(localStorage.getItem('cartTotal')) : state.cartLength;
        state.cartLength = total;
        return state.cartLength;
    },
    checkProductInCart: (state) => (productId) => {
        return state.cart.some(product => product.id === productId);
    }
};

const mutations = {
    setCart: (state, payload) => {
        state.cart = payload;
        localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setCartLength: (state, payload) => {
        state.cartLength = payload;
        localStorage.setItem('cartTotal', JSON.stringify(state.cartLength));
    }
};

const actions = {
    async AddToCart ({ commit, state }, {payload, router}) {
        console.log(payload);

        try {
            const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : state.cart;

            const product = payload;

            const productInCart = cart.find(item => item._id === product._id);

            if (!productInCart) {
                product.price = product.size.price;
                cart.push(product);
            }else {
                productInCart.quantity += product.quantity;
            }

            commit('setCart', cart);
            
            commit('setCartLength', cart.length);

            Swal.fire({
                icon: 'success',
                title:  i18n.global.locale === 'en' ? 'Product Added to Cart' :  i18n.global.locale === 'ar' ? 'تمت الإضافة إلى السلة' : 'Produit ajouté au panier',
                showConfirmButton: true,
                confirmButtonText: i18n.global.locale === 'en' ? 'View Cart' :  i18n.global.locale === 'ar' ? 'عرض السلة' : 'Voir le panier',
                timer: 3000,
                toast: true,
                position: 'top-end'
            }).then((result) => {
                if (result.isConfirmed) {
                    // Use Vue Router to navigate to /cart
                    router.push('/cart');
                }
            });
        
        } catch (error) {
            
            console.log(error);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            });
        }
    },
    async RemoveFromCart ({ commit, state, dispatch }, {product, router}) {
        try {
            const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : state.cart;


            const productInCart = cart.find(item => item._id === product._id);

            if (productInCart) {
                cart.splice(cart.indexOf(productInCart), 1);
            };

            commit('setCart', cart);
            
            commit('setCartLength', cart.length);

            Swal.fire({
                icon: 'success',
                title: i18n.global.locale === 'en' ? 'Product Removed from Cart' :  i18n.global.locale === 'ar' ? 'تمت الإزالة من السلة' : 'Produit retiré du panier',
                showConfirmButton: true,
                confirmButtonText: i18n.global.locale === 'en' ? 'Undo' :  i18n.global.locale === 'ar' ? 'تراجع' : 'Annuler',
                timer: 3000,
                toast: true,
                position: 'top-end'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // Reinstate the product in the cart
                    await dispatch('AddToCart', {payload: product, router})
                }
            });
        
        } catch (error) {
            
            console.log(error);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            });

        }
    },
    async upadateProductInCart ({ commit, state }, payload) {
        try {

            const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : state.cart;

            const product = payload;

            const productInCart = cart.find(item => item._id === product._id);

            if (productInCart) {
                productInCart.quantity = product.quantity;
                productInCart.size = product.size;
                productInCart.price = product.size.price;
                productInCart.style = product.style;
            }
            
            
            // add Prodct to cart replace the old product
            commit('setCart', cart);

            commit('setCartLength', state.cart.length);

        } catch (error) {
            
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            });

        }
    
    },
    async clearCart ({ commit }) {
        try {

            commit('setCart', []);

            commit('setCartLength', 0);
            
        } catch (error) {
            
            console.log(error);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            });

        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
  };