import Swal from "sweetalert2";

const state = {
    messages: [],
    currentMessage: {
        name: "",
        email: "",
        subject: "",
        message: "",
    },
};

const getters = {
    messages: (state) => state.messages,
    currentMessage: (state) => state.currentMessage,
};

const mutations = {
    setMessages(state, messages) {
        state.messages = messages;
    },
    setCurrentMessage(state, message) {
        state.currentMessage = message;
    },
};

const actions = {
    async getAllMessages({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/messages', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setMessages', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getMessage({ commit, dispatch }, id) {
        console.log(id);
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/messages/" + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            let data = await response.json();

            if (data.status == "success") {
                dispatch("stopLoading");
                commit("setCurrentMessage", data.message);
            } else {
                dispatch("stopLoading");
                console.log(data.message);
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async addMessage({ commit, dispatch }, message) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/messages", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
                body: JSON.stringify(message),
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Message added successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
            } else {
                dispatch("stopLoading");
                console.log(data.message);
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteMessage({ commit, dispatch }, message) {
        dispatch("startLoading");
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + "/messages/" + message._id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token"),
                },
            });

            let data = await response.json();

            if (data.status == "success") {
                Swal.fire({
                    title: "Success",
                    text: "Message deleted successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch("stopLoading");
                dispatch("getAllMessages");
            } else {
                dispatch("stopLoading");
                console.log(data.message);
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch("stopLoading");
            Swal.fire({
                title: "Error",
                text: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};