import Swal from "sweetalert2"

const state = {
    token: null,
    user: {}
}

const getters = {
    token: state => state.token,
    user: state => state.user
}

const mutations = {
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
    },
    setUser(state, user) {
        state.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }
}

const actions = {
    async login({ commit, dispatch }, user) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/auth/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user)
            });

            let data = await response.json();

            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setToken', data.token);
                commit('setUser', data.user);
                return true;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async loginAdmin({ commit, dispatch }, admin) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/auth/admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(admin)
            });

            let data = await response.json();

            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setToken', data.token);
                commit('setUser', data.admin);
                return true;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async logout({ commit, dispatch }) {
        dispatch('startLoading');
        commit('setToken', null);
        commit('setUser', {});
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        setTimeout(() => {
            dispatch('stopLoading');
        }, 1000);
    },
    async uploadImages({ commit, dispatch }, images) {
        try {
            let formData = new FormData();

            Array.from(images).forEach(image => {
                formData.append('img', image);
            });

            let response = await fetch(process.env.VUE_APP_SERVERURL + '/upload', {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('token')
                },
                body: formData
            });

            let data = await response.json();

            if(response.status == 200) {
                
                if(data.filenames.length > 1) {

                    return data.filenames;

                }else {

                    return data.filenames[0];
                
                }

            }else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return null;
            }
        } catch (err) {
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return null;
        }
    },
    async generateCode({ commit, dispatch }, email) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/auth/generatecode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: email})
            });

            let data = await response.json();

            if(data.status == 'success') {
                dispatch('stopLoading');
                return true;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async resetPassword({ commit, dispatch }, {email, code, password}) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/auth/resetpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email, code, password})
            });

            let res = await response.json();

            if(res.status == 'success') {
                dispatch('stopLoading');
                return true;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: res.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}

