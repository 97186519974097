<template>
    <nav class="navbar">
        <div class="TitleBox">
            <div class="Sandwich" @click="toggleDrawer()">
                <i class="fa-solid fa-bars"></i>
            </div>
            <router-link class="title" to="/">WIMO <span>store</span></router-link>
            <h2 class="Slogon">Elevate Your Style with <span>Custom Printed Apparel</span></h2>
        </div>
        <div class="Drawer" v-if="DrawerStatus" >
            <div class="CloseDrawer" @click="toggleDrawer()">
                <i class="fa-solid fa-times"></i>
            </div>
            
            <router-link to="/">
                <i class="fa-solid fa-house"></i>
                {{ $t('language.Home') }}
            </router-link>
            <router-link to="/products">
                <i class="fa-solid fa-box"></i>
                {{ $t('language.Products') }}
            </router-link>
            <router-link to="/categories">
                <i class="fa-solid fa-layer-group"></i>
                {{ $t('language.Categories') }}
            </router-link>
            <router-link to="/products">
                <i class="fa-solid fa-layer-group"></i>
                {{ $t('language.Tags') }}
            </router-link>
            <router-link to="/contact">
                <i class="fa-solid fa-circle-question"></i>
                {{ $t('language.FAQANDSUPPORT') }}
            </router-link>
        </div>
        <div class="drawerOverlay" v-if="DrawerStatus" ></div>
        <div class="links">
            <router-link class="FirstLink" to="/cart">
                <i class="fa-solid fa-cart-shopping"></i>
                <p>{{ $t('language.ShoppingCart') }}</p>
                <div class="nbCart" v-if="cartLength > 0">
                    <p>{{cartLength}}</p>
                </div>
            </router-link>
            <p> | </p>
            <router-link to="/login" v-if="!userConnected">
                <i class="fa-solid fa-user"></i>        
                <p>{{ $t('language.LoginRegister') }}</p>
            </router-link>
            <router-link to="/profile" v-if="userConnected">
                <i class="fa-solid fa-user"></i>
                <p>{{ $t('language.Profile') }}</p>
            </router-link>
            <p v-if="userConnected" > | </p>
            <router-link to="/profile?tab=2" v-if="userConnected">
                <i class="fa-solid fa-heart"></i>
                <p>{{ $t('language.Favs') }}</p>
            </router-link>
            <select @change="changeLanguage($event)" v-model="currentUserLocal">
                <option value="en">🇺🇸 English</option>
                <option value="fr">🇫🇷 Français</option>
                <option value="ar">🇹🇳 العربية</option>
            </select>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'NavbarComp',
    data() {
        return {
            nbCart: 1,
            userConnected: localStorage.getItem('user') && localStorage.getItem('token') ? true : false,
            DrawerStatus: false,
            currentUserLocal: this.$i18n.locale
        }
    },
    methods: {
        toggleDrawer() {
            this.DrawerStatus = !this.DrawerStatus;
        },
        closeDrawer() {
            this.DrawerStatus = false;
        },
        changeLanguage(event) {
            this.$i18n.locale = event.target.value;
        },
        detectUserLanguage() {
            const apiKey = '82f68c3a34c64d89804a700233479d17'; // Replace with your actual API key
            fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`)
                .then(response => response.json())
                .then(data => {
                    const userLocale = data.languages.split(',')[0].split('-')[0]; // Get the primary language code
                if (this.$i18n.availableLocales.includes(userLocale)) {
                    this.$i18n.locale = userLocale;
                    this.currentUserLocal = userLocale;
                } else {
                    this.$i18n.locale = 'en';
                    this.currentUserLocal = 'en';
                }
                })
                .catch(() => {
                    this.$i18n.locale = 'en';
                    this.currentUserLocal = 'en';
                });
        }
    },
    mounted() {
        this.userConnected = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
        this.detectUserLanguage();
    },
    computed: {
      ...mapGetters(['cartLength'])  
    },
    //watch route change to update userConnected
    watch: {
        $route(to, from) {
            this.userConnected = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
            this.closeDrawer();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/_variables.scss';

.navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1rem;

    .TitleBox {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .Sandwich {
            display: none;
        }

        .dropdownHolder {
            display: none;
        }

        .title {
            text-decoration: none;
            color: $primary-color;
            font-size: 4rem;
            font-weight: bold;
            
    
            span {
                color: $secondary-color;
                font-family: $secondary-font;
            }
        }

        .Slogon {
            font-size: 1.8rem;
            font-weight: normal;
            color: $primary-color;
            text-align: center;
            margin-top: 0.5rem;

            span {
                color: $secondary-color;
            }
        }
    }

    .Drawer {
        display: none;
    }

    .drawerOverlay {
        display: none;
    }

    .links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        a {
            text-decoration: none;
            color: $primary-color;
            font-size: 1rem;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1rem;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;


            i {
                font-size: 0.9rem;
                margin-right: 0.5rem;
            }

            .nbCart {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.2rem;
                height: 1.2rem;
                background-color: $primary-color;
                color: white;
                border-radius: 50%;
                margin: 0 0 0 0.5rem;
                transition: all 0.3s ease-in-out;

                p {
                    font-size: 0.7rem;
                    font-weight: bold;
                    margin: 0;
                }
            }


            &:hover {
                color: $secondary-color;
                text-decoration: underline;

                i {
                    color: $secondary-color;
                }

                .nbCart {
                    background-color: $secondary-color;
                }
            }
        }

        p {
            font-size: 1rem;
            font-weight: normal;
        }

        
    }
}

@media screen and (max-width: 768px){
    .navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0rem 0.5rem;

        .TitleBox {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .Sandwich {
                display: block;
                font-size: 1.5rem;
                color: $primary-color;
            }

            .title {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                font-size: 1rem;
            }

            .Slogon {
                display: none;
            }
        }

        .Drawer {
            position: fixed;
            top: 0;
            left: 0;
            width: 80%;
            height: 100vh;
            overflow: auto;
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem;
            z-index: 9999;
            transition: all 0.3s ease-in-out;
            box-shadow: none;

            a {
                text-decoration: none;
                color: $primary-color;
                font-size: 1rem;
                font-weight: bold;
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 1rem 0rem;
                border-radius: 5px;
                background-color: white;
                transition: all 0.3s ease-in-out;

                i {
                    font-size: 1.5rem;
                }

                &:hover {
                    color: $secondary-color;
                    text-decoration: underline;
                }
            }

            .CloseDrawer {
                display: flex;
                justify-content: flex-end;
                padding: 1rem;
                cursor: pointer;

                i {
                    font-size: 1.5rem;
                    color: $primary-color;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
        }

        .drawerOverlay {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 999;
            backdrop-filter: blur(5px);
        }


        .links {
            flex-direction: row;


            a {
                font-size: 0.6rem;
                margin: 0 0rem;
                padding: 0.5rem 0.5rem;
                position: relative;
                
                p {
                    display: none;
                }

                i {
                    font-size: 0.8rem;
                }

                .nbCart {
                    width: 1rem;
                    height: 1rem;
                    position: absolute;
                    right: 0;
                    top: 0;

                    p {
                        font-size: 0.8rem;
                        display: block;
                    }
                }
            }

            p {
                font-size: 0.6rem;
                display: none;
            }
        }
    }
}
</style>