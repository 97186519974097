<template>
    <div class="HomeSidebar">
        <router-link to="/">
            <i class="fa-solid fa-house"></i>
            {{ $t('language.Home') }}
        </router-link>
        <router-link to="/products">
            <i class="fa-solid fa-box"></i>
            {{ $t('language.Products') }}
            <div class="DropDown">
                <router-link :to="{ name: 'Home', hash: '#bestSellers' }">
                    <i class="fa-solid fa-box"></i>
                    {{ $t('language.BestSellers') }}
                </router-link>
                <router-link :to="{ name: 'Home', hash: '#NewArrivals' }">
                    <i class="fa-solid fa-box"></i>
                    {{ $t('language.NewArrivals') }}
                </router-link>
                <router-link :to="{ name: 'Home', hash: '#DiscountDeals' }">
                    <i class="fa-solid fa-box"></i>
                    {{ $t('language.DiscountDeals') }}
                </router-link>
            </div>
        </router-link>
        <router-link to="/categories">
            <i class="fa-solid fa-layer-group"></i>
            {{ $t('language.Categories') }}
            <div class="DropDown">
                <router-link :to="'/categories?catName=' + this.removeSpaces(cat.name)" v-for="(cat, index) in categories.slice(0, 4)" :key="index">
                    <i class="fa-solid fa-layer-group"></i>
                    <span v-if="$i18n.locale == 'en'">{{cat.name}}</span>
                    <span v-if="$i18n.locale == 'fr'">{{cat.nameFr}}</span>
                    <span v-if="$i18n.locale == 'ar'">{{cat.nameAr}}</span>
                </router-link>
            </div>
        </router-link>
        <router-link to="/contact">
            <i class="fa-solid fa-circle-question"></i>
            {{ $t('language.FAQANDSUPPORT') }}
        </router-link>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'HomeSidebar',
    data() {
        return {
            TagsList: []
        }
    },
    methods: {
        ...mapActions(['getAllCategories', 'getAllProducts']),
        removeSpaces(str) {
            return str.replace(/\s+/g, '-').toLowerCase()
        },
        openHome() {
            this.$router.push('/');
        },
        openDropDown(id) {
            const dropDown = document.getElementById(id);
            console.log(dropDown.style.display);
            dropDown.style.display = dropDown.style.display === 'none' ? 'flex' : 'none';

            // Close other dropdowns
            const dropDowns = document.querySelectorAll('.DropDownList');
            dropDowns.forEach(dropDown => {
                if (dropDown.id !== id) {
                    dropDown.style.display = 'none';
                }
            });
        },
    },
    computed: {
        ...mapGetters(['categories', 'products'])
    },
    async mounted() {
        await this.getAllCategories();
        await this.getAllProducts();

        this.products.forEach(product => {
            product.tags.forEach(tag => {
                if (!this.TagsList.includes(tag)) {
                    this.TagsList.push(tag);
                }
            });
        });
    },
    watch: {
        products() {
            this.products.forEach(product => {
                product.tags.forEach(tag => {
                    if (!this.TagsList.includes(tag)) {
                        this.TagsList.push(tag);
                    }
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/_variables.scss';

.HomeSidebar {
    width: 15%;

    a {
        text-decoration: none;
        color: $primary-color;
        font-size: 1rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.4rem 0rem;
        border-radius: 5px;
        background-color: white;
        transition: all 0.3s ease-in-out;
        position: relative;

        i {
            font-size: 1.5rem;
        }

        .DropDown {
            display: none;
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            background-color: white;
            z-index: 100;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0.5rem;
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

            a {
                display: block;
                padding: 1rem;
                border-radius: 5px;
                transition: all 0.3s ease-in-out;
                font-size: 0.8rem;
                width: 100%;

                i {
                    font-size: 0.8rem;
                }

                &:hover {
                    background-color: $primary-color;
                    color: white;
                }
            }
        }

        &:hover {
            color: $secondary-color;
            text-decoration: underline;

            .DropDown {
                display: flex;
            }
        }

        .DropDownList {
            display: none;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

            a {
                display: none;
                padding: 1rem;
                border-radius: 5px;
                transition: all 0.3s ease-in-out;
                font-size: 0.8rem;

                i {
                    font-size: 0.8rem;
                }

                &:hover {
                    background-color: $primary-color;
                    color: white;
                }
            }
        }
    }

    .dropdownHolder {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .HomeSidebar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;

        a {
            display: none;
        }

        .dropdownHolder {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            background-color: white;
            color: $primary-color;
            font-size: 0.7rem;
            font-weight: bold;
            transition: all 0.3s ease-in-out;
            position: relative;

            i {
                font-size: 0.7rem;
            }

            .DropDownList {
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                width: 140px;
                background-color: white;
                z-index: 100;
                align-items: flex-start;
                flex-direction: column;
                gap: 0.5rem;
                padding: 0.5rem;

                a {
                    display: block;
                    padding: 1rem;
                    border-radius: 5px;
                    transition: all 0.3s ease-in-out;
                    font-size: 0.8rem;

                    i {
                        font-size: 0.8rem;
                    }

                    &:hover {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }


            .LastList {
                left: unset;
                right: 0;
            }
        }

        .Sandwich {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 1rem;
            background-color: white;
            color: $primary-color;
            font-size: 1.5rem;
            font-weight: bold;
            transition: all 0.3s ease-in-out;

            i {
                font-size: 1.7rem;
            }

            &:hover {
                color: $secondary-color;
                text-decoration: underline;
            }
        }

        
    }
}
</style>