<template>
    <div class="LoaderComp">
        <div class="loader"></div>
    </div>
</template>
<script>
export default {
    name: 'LoaderComp',
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/_variables.scss';
.LoaderComp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .loader {
        border: 8px solid #e2e2e2;
        border-top: 8px solid $primary-color;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 0.8s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>