import Swal from "sweetalert2"
import i18n from '@/i18n';

const state = {
    users: [],
    currentUser: {}
}

const getters = {
    users: state => state.users,
    currentUser: state => state.currentUser
}

const mutations = {
    setUsers: (state, users) => state.users = users,
    setCurrentUser: (state, user) => state.currentUser = user
}

const actions = {
    async getAllUsers({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');    
                await commit('setUsers', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getUser({ commit, dispatch }, id) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setCurrentUser', data.user);
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return null;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return null;
        }
    },
    async addUser({ commit, dispatch }, {user, router}) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(user)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'User added successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                let res = await dispatch('login', { email: user.email, password: user.password });

                if(res) {
                    if(data.user.role == 'user') {
                        router.push('/profile');
                    }
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Something went wrong',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }

            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updateUser({ commit, dispatch }, user) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users/' + user._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(user)
            });

            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'User updated successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });

                dispatch('stopLoading');
                await dispatch('getUser', user._id);
            }else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                dispatch('stopLoading');
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteUser({ commit, dispatch }, user) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users/' + user._id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });
    
            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setUsers', data.Users);
                Swal.fire({
                    title: 'Success',
                    text: 'User Deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return true;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }
        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        }
        
    },
    async addFavs({commit, dispatch}, user ) {
        console.log(user);
        try {         
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users/' + user.user._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({favs: user.user.favs})
            });

            let data = await response.json();
            if(data.status == 'success') {
                commit('setCurrentUser', data.user);
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    icon: 'success',
                    text:  i18n.global.locale === 'en' ? 'Product added to favorites' :  i18n.global.locale === 'ar' ? 'تمت الإضافة إلى المفضلة' : 'Produit ajouté aux favoris'
                });
            }else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteFavs({commit, dispatch}, user ) {
        console.log(user);
        try {         
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/users/' + user.user._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({favs: user.user.favs})
            });

            let data = await response.json();
            if(data.status == 'success') {
                commit('setCurrentUser', data.user);
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    icon: 'success',
                    text: i18n.global.locale === 'en' ? 'Product removed from favorites' :  i18n.global.locale === 'ar' ? 'تمت إزالة المنتج من المفضلة' : 'Produit retiré des favoris'  
                });
            }else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}

