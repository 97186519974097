import Swal from "sweetalert2"

const state = {
    SubCategories: [],
    currentSubCategories: {},
}

const getters = {
    SubCategories: state => state.SubCategories,
    currentSubCategories: state => state.currentSubCategories,
}

const mutations = {
    setSubCategories: (state, SubCategories) => state.SubCategories = SubCategories,
    setcurrentSubCategories: (state, subCategory) => state.currentSubCategories = subCategory,
}

const actions = {
    async getAllSubCategories({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/subcategories', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                commit('setSubCategories', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getsubCategory({ commit, dispatch }, id) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/subcategories/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setcurrentSubCategories', data.subcategory);
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async addsubCategory({ commit, dispatch }, subCategory) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/subcategories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(subCategory)
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Success',
                    text: 'subCategory added successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updatesubCategory({ commit, dispatch }, subCategory) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/subcategories/' + subCategory._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(subCategory)
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Success',
                    text: 'subCategory updated successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteSubCategory({ commit, dispatch }, subCategory) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/subcategories/' + subCategory._id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });
    
            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Success',
                    text: 'subCategory Deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return true;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }
        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        }
        
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}

