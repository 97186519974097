import Swal from "sweetalert2"

const state = {
    orders: [],
}

const getters = {
    orders: state => state.orders,
}

const mutations = {
    setOrders: (state, orders) => state.orders = orders,
}

const actions = {
    async getAllOrders({ commit, dispatch }) {
        dispatch('startLoading');
        
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/orders', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();

            if(response.status == 200) {
                dispatch('stopLoading');
                await commit('setOrders', data);
            }else {
                dispatch('stopLoading');
                console.log(data.message);
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async getOrder({ commit, dispatch }, id) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/orders/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                return data.Order;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return null;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return null;
        }
    },
    async addOrder({ commit, dispatch }, order) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/orders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(order)
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                commit('setOrders', data.Orders);
                Swal.fire({
                    title: 'Success',
                    text: 'Order added successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async updateOrder({ commit, dispatch }, order) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/orders/' + order._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(order)
            });

            let data = await response.json();
            if(data.status == 'success') {
                dispatch('stopLoading');
                dispatch('getAllOrders');
                Swal.fire({
                    title: 'Success',
                    text: 'Order updated successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }else {
                dispatch('stopLoading');
                dispatch('getAllOrders');   
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } catch (err) {
            dispatch('stopLoading');
            dispatch('getAllOrders');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    },
    async deleteOrder({ commit, dispatch }, order) {
        dispatch('startLoading');
        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/orders/' + order._id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });
    
            let data = await response.json();
            if(data.status == 'success') {
                Swal.fire({
                    title: 'Success',
                    text: 'Order Deleted successfully',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                
                dispatch('stopLoading');
                await dispatch('getAllOrders');
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
    },
    async konnectGenUrl({ commit, dispatch }, orderDetails) {
        dispatch('startLoading');

        try {
            let response = await fetch(process.env.VUE_APP_SERVERURL + '/payments/konnect', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(orderDetails)
            });

            let data = await response.json();
            if(response.status == 200) {
                dispatch('stopLoading');
                return data.data.payUrl;
            }else {
                dispatch('stopLoading');
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return null;
            }

        } catch (err) {
            dispatch('stopLoading');
            Swal.fire({
                title: 'Error',
                text: err.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            return null;
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}

