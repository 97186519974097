<template>
    <div class="images">
        <a :href="ad.url" v-for="(ad, index) in this.productads" :key="index">
            <img :src="getImageUrl(ad)" alt="ad1">
        </a>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'HomeAds',
    data() {
        return {
            AllAds: []
        }
    },
    methods: {
        ...mapActions(['getAllProductAds']),
        getImageUrl(ad) {
            return process.env.VUE_APP_SERVERBASEURL + ad.image;
        }
    },
    computed: {
        ...mapGetters(['productads'])
    },
    async mounted() {
        await this.getAllProductAds();

    }
}
</script>

<style lang="scss" scoped>
.images {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 15%;

    img {
        width: 200px;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
        background-color: #ccc;
    }
}

@media screen and (max-width: 768px) {
    .images {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;

        img {
            width: 50%;
            height: auto;
            object-fit: cover;
        }
    }
}
</style>